import Modal from 'react-bootstrap/Modal'
import { Form, Row, Col, Button } from 'react-bootstrap'
import RECORRENCY_TYPES from 'helpers/recurrencyTypes'
import { NumericFormat } from 'react-number-format'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import { updateSignatureInvoices } from 'services/vittamais-core/invoice/invoice'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import { useState, useEffect } from 'react'
interface IPropsModal {
	show: boolean
	onClose: (data?: { action?: string }) => void
	subscriptionData: any
}
function ModalEditSubscription({
	subscriptionData,
	show,
	onClose
}: IPropsModal) {
	const { showConfirm, showAlert } = useSweetAlert()
	const { showToast } = useToast()
	const [showLoading, setShowLoading] = useState(false)
	const initialState = {
		next_date: '',
		recurrency: 0,
		month_value: 0,
		payment_method: PAYMENT_METHOD.ASK_THE_CUSTOMER,
		description: ''
	}
	const [formData, setFormData] = useState(initialState)
	const handleFieldChange = (name: string, e: any): void => {
		if (name == 'month_value') {
			setFormData({
				...formData,
				[name]: e
			})
		} else {
			setFormData({
				...formData,
				[name]: e.target.value
			})
		}
	}
	const onsubmit = async () => {
		try {
			setShowLoading(true)
			if (
				!formData.next_date ||
				!formData.month_value ||
				!formData.payment_method ||
				//!formData.installments ||
				!formData.description
			) {
				showAlert({
					type: 'warning',
					title: 'Atenção!',
					text: 'É necessário preencher todos os campos!'
				})
				setShowLoading(false)
				return
			}
			
			let dataToUpdate: any = {
				subscription_id: subscriptionData?.id
			}
			
			if (formData.month_value) dataToUpdate.value = (formData.month_value*100).toFixed(0)
			//if (formData.installments) dataToUpdate.installment = formData.installments
			if (formData.payment_method)
				dataToUpdate.payment_method = formData.payment_method
			if (formData.description) dataToUpdate.description = formData.description
			if (formData.next_date) dataToUpdate.next_part_date = formData.next_date

			await updateSignatureInvoices(dataToUpdate)

			showToast('Assinatura atualizada com sucesso!', 'success')
			onClose({ action: 'refresh' })
		} catch (e: any) {
			setShowLoading(false)
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Algo deu errado, tente novamente mais tarde.'
			})
		}
		setShowLoading(false)
	}
	const clearData = () => {
		setFormData(initialState)
	}
	useEffect(() => {
		// console.log(subscriptionData)
		if (subscriptionData) {
			let payment_method: any = PAYMENT_METHOD.ASK_THE_CUSTOMER
			switch (subscriptionData.payment_method) {
				case PAYMENT_METHOD.ASK_THE_CUSTOMER:
					payment_method = PAYMENT_METHOD.ASK_THE_CUSTOMER
					break
				case PAYMENT_METHOD.TICKET:
					payment_method = PAYMENT_METHOD.TICKET
					break
				case PAYMENT_METHOD.CREDIT_CARD:
					payment_method = PAYMENT_METHOD.CREDIT_CARD
					break
				case PAYMENT_METHOD.PIX:
					payment_method = PAYMENT_METHOD.PIX
					break
				default:
					payment_method = PAYMENT_METHOD.ASK_THE_CUSTOMER
					break
			}
			const dataAtual = new Date()
				const ano = dataAtual.getFullYear()
				const mes = dataAtual.getMonth() + 1
				const dia = dataAtual.getDate()
				const dataFormatada = `${ano}-${mes.toString().padStart(2, '0')}-${dia
					.toString()
					.padStart(2, '0')}`
			
				const data = {
					next_date: dataFormatada,
					recurrency: subscriptionData.recurrency,
					month_value: subscriptionData.total_value,
					payment_method: payment_method,
					description: 'Vittá + Vantagens'
				}
				setFormData(data)
		}
	}, [show])

	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				// onHide={() => {
				// 	onClose()
				// }}
				size='lg'
			>
				<Modal.Header>
					<Modal.Title>Editar Assinatura</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col sm='6'>
							<Form.Group>
								<Form.Label>Próxima data</Form.Label>
								<Form.Control
									name='next_date'
									onChange={e => {
										handleFieldChange('next_date', e)
									}}
									type='date'
									value={formData.next_date}
								/>
							</Form.Group>
							</Col>
							<Col sm='6'>
							<Form.Group>
								<Form.Label>Forma de pagamento</Form.Label>
								<Form.Select
									name='payment_method'
									value={formData.payment_method}
									onChange={e => {
										handleFieldChange('payment_method', e)
									}}
								>
									<option value=''>Selecionar</option>
									{/* <option value={PAYMENT_METHOD.ASK_THE_CUSTOMER}>
										Pergunte ao cliente
									</option> */}
									<option value={PAYMENT_METHOD.TICKET}>Boleto/Pix</option>
									<option value={PAYMENT_METHOD.CREDIT_CARD}>Cartão de Crédito</option>
									{/* <option value={PAYMENT_METHOD.PIX}>PIX</option> */}
								</Form.Select>
							</Form.Group>
					
						</Col>
						{/* <Col sm='6'>
							<Form.Group>
								{formData.recurrency == RECORRENCY_TYPES.YEARLY ? (
									<Form.Label>Valor anual</Form.Label>
								) : formData.recurrency ==  RECORRENCY_TYPES.SEMIANNUALLY ? (
									<Form.Label>Valor semestral</Form.Label>
								):(
									<Form.Label>Valor mensal</Form.Label>
								)}

								<NumericFormat
									name='month_value'
									displayType={'input'}
									thousandSeparator={'.'}
									decimalSeparator={','}
									decimalScale={2}
									fixedDecimalScale={true}
									value={formData.month_value}
									prefix={'R$ '}
									className='form-control'
									onValueChange={values =>
										handleFieldChange('month_value', values.floatValue)
									}
								/>
							</Form.Group>
						</Col> */}
						{/* <Col sm='6'>
							<Form.Group>
								<Form.Label>Recorrência</Form.Label>
								<Form.Select
									name='recurrency'
									onChange={e => {
										handleFieldChange('recurrency', e)
									}}
									value={formData.recurrency}
								>
									<option value=''>Selecionar</option>
									<option value={RECORRENCY_TYPES.MONTHLY}>Mensal</option>
									<option value={RECORRENCY_TYPES.YEARLY}>Anual</option>
								</Form.Select>
							</Form.Group>
						</Col> */}
					</Row>
					<Row className='mt-2'>
						
						<Col >
							<Form.Label>Descrição</Form.Label>
							<Form.Control
								value={formData.description}
								name='description'
								as='textarea'
								rows={3}
								onChange={e => {
									handleFieldChange('description', e)
								}}
							/>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => {
							onClose()
						}}
						disabled={showLoading}
					>
						Fechar
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							onsubmit()
						}}
						disabled={showLoading}
					>
						{showLoading ? 'Atualizando...' : 'Atualizar'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ModalEditSubscription
