import Modal from 'react-bootstrap/Modal'
import {
	OptionContainer,
	IconWrapper,
	Text,
	ClickText,
	OptionContent
} from './styles/ActionOption'
import { AiOutlinePrinter } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { hasEspecificPermission } from 'helpers/hasEspecificPermission'
import useAuth from 'hooks/useAuth'
import { TiBusinessCard } from 'react-icons/ti'
import { BiTrafficCone } from 'react-icons/bi'
interface propsOnClose {
	action?: string
}
interface IPropsModal {
	show: boolean
	onClose: (data?: { action?: string }) => void
	invoice: []
	subscriptionData:any
}
function ModalOfActions({ show, onClose, invoice, subscriptionData }: IPropsModal) {
	const { dataAuth } = useAuth()
	if (!show) return null
	return (
		<>
			<Modal
				show={show}
				onHide={() => {
					onClose()
				}}
				size='lg'
				centered
			>
				<Modal.Body>
					{hasEspecificPermission(dataAuth?.user?.access_roles, 'view_invoice') && subscriptionData.payment_method !=7 ? (
						<OptionContainer
							onClick={() => {
								onClose({ action: 'print_meat' })
							}}
						>
							<OptionContent>
								<IconWrapper className='IconWrapper'>
									<AiOutlinePrinter size={30} />
								</IconWrapper>
								<Text className='TextWrapper'>Imprimir Carnê</Text>
							</OptionContent>
							<ClickText className='TextWrapper'>clique aqui</ClickText>
						</OptionContainer>
					) : null}
					{hasEspecificPermission(
						dataAuth?.user?.access_roles,
						'edit_subscription'
					) && subscriptionData.payment_method !=7 ? (
						<OptionContainer
							onClick={() => {
								onClose({ action: 'edit_signature' })
							}}
						>
							<OptionContent>
								<IconWrapper className='IconWrapper'>
									<FiEdit size={25} />
								</IconWrapper>
								<Text className='TextWrapper'>Editar Assinatura</Text>
							</OptionContent>
							<ClickText className='TextWrapper'>clique aqui</ClickText>
						</OptionContainer>
					) : null}
					{hasEspecificPermission(
						dataAuth?.user?.access_roles,
						'renegotiate_invoice'
					) && subscriptionData.payment_method !=7 ? (
						<OptionContainer
							onClick={() => {
								onClose({ action: 'renegotiate' })
							}}
						>
							<OptionContent>
								<IconWrapper className='IconWrapper'>
									<TiBusinessCard size={30} />
								</IconWrapper>
								<Text className='TextWrapper'>Renegociar</Text>
							</OptionContent>
							<ClickText className='TextWrapper'>clique aqui</ClickText>
						</OptionContainer>
					) : null}
					{hasEspecificPermission(dataAuth?.user?.access_roles, 'late_fee') ? (
						<OptionContainer
							onClick={() => {
								onClose({ action: 'cancellation_penally' })
							}}
						>
							<OptionContent>
								<IconWrapper className='IconWrapper'>
									<BiTrafficCone size={30} />
								</IconWrapper>
								<Text className='TextWrapper'>Gerar Multa de Cancelamento</Text>
							</OptionContent>
							<ClickText className='TextWrapper'>clique aqui</ClickText>
						</OptionContainer>
					) : null}
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ModalOfActions
