import { Form } from "react-bootstrap";
import RECORRENCY_TYPES from 'helpers/recurrencyTypes'

interface RecurrencyProps {
  onChange: any,
  isDisabled: boolean,
  value: any
}

export function Recurrency({ onChange, isDisabled, value }: RecurrencyProps) {
  return (
    <Form.Group>
      <Form.Label>Recorrência</Form.Label>
      <Form.Select
        name='recurrency'
        onChange={onChange}
        disabled={isDisabled}
        value={value}
      >
        <option value=''>Selecionar</option>
        <option value={RECORRENCY_TYPES.MONTHLY}>Mensal</option>
        <option value={RECORRENCY_TYPES.YEARLY}>Anual</option>
        <option value={RECORRENCY_TYPES.SEMIANNUALLY}>Semestral</option>
      </Form.Select>
    </Form.Group>
  )
}