import { FC, useState, useContext, useEffect } from 'react'
import {
	Card,
	Button,
	Row,
	Col,
	Table,
	Form,
	Dropdown
} from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { AiFillEdit, AiFillSave } from 'react-icons/ai'
import SoftBadge from 'components/common/SoftBadge'
import AppContext from 'context/Context'
import { ImCancelCircle, ImFileText, ImTab } from 'react-icons/im'
import { BsDownload } from 'react-icons/bs'
import debounce from 'debounce-promise'
import { BsFillSendFill, BsFillPersonVcardFill } from 'react-icons/bs'
import { AiFillSetting } from 'react-icons/ai'
import CustomLinkLeft from 'components/common/CustomLinkLeft'
import { search as searchMembership } from 'services/vittamais-core/membership-tier/membership'
import { search as searchCustomer } from 'services/vittamais-core/customer/customer'
import { calculateContractValuesAddemdum, createContractAddendum, editContractAddendum, search as searchContract } from 'services/vittamais-core/contract/contract'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import useAuth from 'hooks/useAuth'
import {
	IDataCreateContract,
	IFormData,
	IDataViewContract,
	ICalculateContractValues,
	IResponseCalculateContractValues
} from './interfaces'
import {
	createContract,
	loadDataEditform,
	calculateContractValues,
	editContract,
	seeSignedContract,
	searchAddendum
} from 'services/vittamais-core/contract/contract'
import useSweetAlert from 'context/Alerts/SweetAlert'
import { useToast } from 'context/Toasts/Toastify'
import CONTRACT_STATUS from 'helpers/contractStatus'
import CancelContractModal from './components/CancelContractModal'
import SendContractModal from './components/SendContractModal'
import { useLoading } from 'context/LoadingBar/LoadingBar'
import { GiConfirmed, GiCancel } from 'react-icons/gi'
import MEMBERSHIP_TYPES from 'helpers/membershipTypes'
import { search as searchAdditionalProducts } from 'services/vittamais-core/additional-product/additionalProduct'
import CustomLoading from 'components/common/CustomLoading'
import CustomPagination from 'components/common/CustomPagination'
import { IResponseSearchData, IDataContract } from '../sublist/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RECURRENCY_TYPE from 'helpers/recurrencyTypes'
import { AddendumType } from '../FormComponents/AddendumType'
import { ContractType } from '../FormComponents/ContractType'
import { MainContractByAddendum } from '../FormComponents/MainContractByAddendum'
import { BusinessPartner } from '../FormComponents/BusinessPartner'
import { DependentsQuantity } from '../FormComponents/DependentsQuantity'
import { Recurrency } from '../FormComponents/Recurrency'
import { MembershipTier } from '../FormComponents/MembershipTier'
import { PaymentMethod } from '../FormComponents/PaymentMethod'
import { Pdv } from '../FormComponents/Pdv'
import { AdditionalProducts } from '../FormComponents/AdditionalProducts'
import { CalculateContractValues } from '../FormComponents/CalculateContractValues'
import { Price } from '../FormComponents/Price'
import { Value } from '../FormComponents/Value'
import { Discount } from '../FormComponents/Discount'
import { ChargeByHolder } from '../FormComponents/ChargeByHolder'
import { BusinessConsultant } from '../FormComponents/BusinessConsultant'
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'
import SUBSCRIPTION_STATUS from 'helpers/subscriptionStatus'
const FormContracts: FC = function FormContracts() {
	const { setLoading } = useLoading()
	const {
		config: { isDark, isRTL },
		setConfig
	} = useContext(AppContext)
	const { showToast } = useToast()
	const { showConfirm, showAlert } = useSweetAlert()
	const { dataAuth } = useAuth()
	const navigate = useNavigate()
	const { id, contractId } = useParams()
	const [showInputValues, setShowInputValues] = useState<boolean>(false)
	const [editable, setEditable] = useState<boolean>(false)
	const [showLoadingCalculateValues, setShowLoadingCalculateValues] =
		useState<boolean>(false)
	const [showChargeByHolder, setShowChargeByHolder] = useState<boolean>(false)
	const [showLoading, setShowLoading] = useState(false)
	const [FormData, setFormData] = useState<IFormData>({
		user_id: '',
		business_partner_id: '',
		membership_tier_id: '',
		pdv_id: dataAuth?.origin_scope?.pdv_origin?.id,
		holder_price: 0,
		holder_dependents_quantity: 0,
		recurrency: 0,
		payment_method: 0,
		price: 0,
		discount: 0,
		value: 0,
		next_part_date: '',
		status: '',
		cancelled_at: '',
		cancelled_by: '',
		cancel_reason: '',
		cancel_obs: '',
		signed_at: '',
		sign_requested_at: '',
		channel_sign_requested: '',
		created_at: '',
		signed_file_url: '',
		original_file_url: '',
		cc_name_user: '',
		additional_products: [],
		total_additional_products_value: 0,
		addendum_id: '',
		addendum_type: '',
		contract_type: '',
		contract_primary_id: '',
		subscriptions: [],
	})
	const [controlCancelContractModal, setCancelContractModal] = useState<{
		show: boolean
		contract_id: number | null
	}>({
		show: false,
		contract_id: null
	})
	const [controlSendModalContract, setControlSendModalContract] = useState<{
		show: boolean
		contract_id: number | null
	}>({
		show: false,
		contract_id: null
	})
	const [disabledContractType, setDisabledContractType] = useState<boolean>(true)
	const [disabledAddendumType, setDisabledAddendumType] = useState<boolean>(true)
	const [disabledAddendumId, setDisabledAddendumId] = useState<boolean>(true)
	const handleFieldChange = (e: any): void => {
		setFormData({
			...FormData,
			[e.target.name]: e.target.value
		})
	}

	const handleFieldCheck = (e: any): void => {
		if (e.target.name === 'holder_dependents_quantity' && e.target.value < 0) {
			e.target.classList.add('is-invalid')
			showToast('O número de dependentes não pode ser menor que 0!', 'error')
		} else {
			e.target.classList.remove('is-invalid')
		}
	}

	const handleContractLoad = (): void => {
		if (contractId) {
			setDisabledContractType(true)
			setDisabledAddendumType(false)
			setDisabledAddendumId(true)
		}
	}

	const handleValueFieldChange = (name: string, event: any): void => {
		//os inputs são disableds
		if (name == 'holder_price') {
			setFormData({
				...FormData,
				[name]: event.floatValue
			})
		}
	}
	const handleSubmit = async (e: any) => {
		e.preventDefault()
		try {
			let business_partner_id: number | string = ''
			let addendum_id: any = ''
			let recurrency: number = parseInt(FormData?.recurrency?.toString())
			let payment_method: number = parseInt(FormData?.payment_method?.toString())
			const mainSub = FormData?.subscriptions.find((e) => !e.holder_subscription_id && e.status != SUBSCRIPTION_STATUS.CANCELED)
			if (FormData?.business_partner_id) {
				business_partner_id = FormData.business_partner_id.value
			}

			if (FormData?.addendum_id) {
				addendum_id = FormData.addendum_id.value
			}


			if (FormData.holder_dependents_quantity < 0) {
				return
			}
			if (
				FormData?.additional_products?.find(
					(e) => e.value == process.env.REACT_APP_VITTAMAIS_NEXTRON_ADDITIONAL_PRODUCT
				)
			) {
				if (mainSub) {
					if (mainSub?.membership_tier_id != 11 && FormData?.membership_tier_id != 11) return showAlert({
						title: 'Atenção!',
						text: "Produto adicional de energia apenas para plano Black",
						type: 'info'
					})
					else if (mainSub?.recurrency != RECURRENCY_TYPE.YEARLY || mainSub?.payment_method != PAYMENT_METHOD.CCP) {
						let confirmed = await showConfirm({
							title: 'Atenção!',
							text: 'A recorrência será alterada para anual, e o método de pagamento será atualizado para cartão de crédito parcelado, devido à inclusão de um produto adicional de desconto em energia'
						})
						if (!confirmed) return
						recurrency = RECURRENCY_TYPE.YEARLY
						payment_method = PAYMENT_METHOD.CCP
						setFormData({
							...FormData,
							recurrency: RECURRENCY_TYPE.YEARLY,
							payment_method: PAYMENT_METHOD.CCP
						})
					}
				}else{
					if (FormData?.membership_tier_id != 11) return showAlert({
						title: 'Atenção!',
						text: "Produto adicional de energia apenas para plano Black",
						type: 'info'
					})
					else if (FormData?.recurrency != RECURRENCY_TYPE.YEARLY || FormData?.payment_method != PAYMENT_METHOD.CCP){
						let confirmed = await showConfirm({
							title: 'Atenção!',
							text: 'A recorrência será alterada para anual, e o método de pagamento será atualizado para cartão de crédito parcelado, devido à inclusão de um produto adicional de desconto em energia'
						})
						if (!confirmed) return
						recurrency = RECURRENCY_TYPE.YEARLY
						payment_method = PAYMENT_METHOD.CCP
						setFormData({
							...FormData,
							recurrency: RECURRENCY_TYPE.YEARLY,
							payment_method: PAYMENT_METHOD.CCP
						})
					}
				}
			}
			let dataSend: IDataCreateContract = {
				business_partner_id: business_partner_id,
				membership_tier_id: FormData.membership_tier_id,
				pdv_id: parseInt(FormData?.pdv_id?.toString()),
				holder_price: FormData?.holder_price * 100,
				holder_dependents_quantity: parseInt(
					FormData?.holder_dependents_quantity?.toString()
				),
				recurrency: recurrency,
				payment_method: payment_method,
				price: FormData?.price,
				discount: FormData?.discount,
				value: FormData?.value,
				additional_products_ids: FormData?.additional_products?.map(
					(item: any) => item?.value
				),
				addendum_id: addendum_id,
				addendum_type: FormData.addendum_type,
				status: 1
			}
			let contract_id = null
			if (contractId) {
				contract_id = await createContractAddendum(dataSend)
				showToast('Contrato criado com sucesso!', 'success')
				navigate(`/contratos/${dataSend.addendum_id}/aditivo/${contract_id}`)
			} else {
				contract_id = await createContract(dataSend)
				showToast('Contrato criado com sucesso!', 'success')
				navigate(`/contratos/${contract_id}`)
			}

		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_code == "additional_product_existent") {
				showAlert({
					title: 'Atenção!',
					text: e?.response?.data?.error_message,
					type: 'info'
				})
				return
			}
			if (e?.response?.data?.error_message) {
				showAlert({
					title: 'Ops...!',
					text: e?.response?.data?.error_message,
					type: 'error'
				})
				return
			}
			showAlert({
				title: 'Ops...!',
				text: 'Ocorreu algo inesperado, tente novamente mais tarde...',
				type: 'error'
			})
		}
	}

	const handleEditSubmit = async (e: any) => {
		e.preventDefault()
		try {
			let business_partner_id: number | string = ''
			let addendum_id: any = ''
			let recurrency: number = parseInt(FormData?.recurrency?.toString())
			let payment_method: number = parseInt(FormData?.payment_method?.toString())
			const mainSub = FormData?.subscriptions.find((e) => !e.holder_subscription_id && e.status != SUBSCRIPTION_STATUS.CANCELED)
			if (FormData?.business_partner_id) {
				business_partner_id = FormData.business_partner_id.value
			}

			if (FormData?.addendum_id) {
				addendum_id = FormData.addendum_id.value
			}
			if (
				FormData?.additional_products?.find(
					(e) => e.value == process.env.REACT_APP_VITTAMAIS_NEXTRON_ADDITIONAL_PRODUCT
				)
			) {
				if (mainSub) {
					if (mainSub?.membership_tier_id != 11 && FormData?.membership_tier_id != 11) return showAlert({
						title: 'Atenção!',
						text: "Produto adicional de energia apenas para plano Black",
						type: 'info'
					})
					else if (mainSub?.recurrency != RECURRENCY_TYPE.YEARLY || mainSub?.payment_method != PAYMENT_METHOD.CCP) {
						let confirmed = await showConfirm({
							title: 'Atenção!',
							text: 'A recorrência será alterada para anual, e o método de pagamento será atualizado para cartão de crédito parcelado, devido à inclusão de um produto adicional de desconto em energia'
						})
						if (!confirmed) return
						recurrency = RECURRENCY_TYPE.YEARLY
						payment_method = PAYMENT_METHOD.CCP
						setFormData({
							...FormData,
							recurrency: RECURRENCY_TYPE.YEARLY,
							payment_method: PAYMENT_METHOD.CCP
						})
					}
				}else{
					if (FormData?.membership_tier_id != 11) return showAlert({
						title: 'Atenção!',
						text: "Produto adicional de energia apenas para plano Black",
						type: 'info'
					})
					else if (FormData?.recurrency != RECURRENCY_TYPE.YEARLY || FormData?.payment_method != PAYMENT_METHOD.CCP){
						let confirmed = await showConfirm({
							title: 'Atenção!',
							text: 'A recorrência será alterada para anual, e o método de pagamento será atualizado para cartão de crédito parcelado, devido à inclusão de um produto adicional de desconto em energia'
						})
						if (!confirmed) return
						recurrency = RECURRENCY_TYPE.YEARLY
						payment_method = PAYMENT_METHOD.CCP
						setFormData({
							...FormData,
							recurrency: RECURRENCY_TYPE.YEARLY,
							payment_method: PAYMENT_METHOD.CCP
						})
					}
				}
			}

			let dataSend: IDataCreateContract = {
				business_partner_id: business_partner_id,
				membership_tier_id: FormData?.membership_tier_id,
				pdv_id: parseInt(FormData?.pdv_id?.toString()),
				holder_price: FormData?.holder_price * 100,
				holder_dependents_quantity: parseInt(
					FormData?.holder_dependents_quantity?.toString()
				),
				recurrency: recurrency,
				payment_method: payment_method,
				price: FormData?.price,
				discount: FormData?.discount,
				value: FormData?.value,
				status: 1,
				additional_products_ids: FormData?.additional_products?.map(
					(item: any) => item?.value
				),
				addendum_id: addendum_id,
				addendum_type: FormData?.addendum_type,
				contract_type: FormData?.addendum_type === null ? 'contract' : 'addendum'
			}
			if (id) {
				if (contractId) {
					await editContractAddendum(parseInt(contractId), dataSend)
				} else {
					await editContract(parseInt(id), dataSend)
				}

			}
			setEditable(false)

			if (id && contractId) await loadDataViewPageByMainContract()
			else await loadDataViewPage()
			showToast('Contrato editado com sucesso!', 'success')
		} catch (e: any) {
			// setEditable(false)
			console.log(e)
			if (e?.response?.data?.error_code == "additional_product_existent") {
				showAlert({
					title: 'Atenção!',
					text: e?.response?.data?.error_message,
					type: 'info'
				})
				return
			}
			if (e?.response?.data?.error_message) {
				showAlert({
					title: 'Ops...!',
					text: e?.response?.data?.error_message,
					type: 'error'
				})
				return
			}
			showAlert({
				title: 'Ops...!',
				text: 'Ocorreu algo inesperado, tente novamente mais tarde...',
				type: 'error'
			})
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => {
			const isDisabled = state.selectProps.isDisabled

			let color = isDark ? 'white' : ''
			let backgroundColor = isDark ? '#0b1727' : ''
			let borderColor = isDark ? '#344050' : '#dae3f0'

			if (isDisabled && !isDark) {
				backgroundColor = '#edf2f9'
				color = '#4a4f5e'
				borderColor = '#dce5f1'
			}

			return {
				...provided,
				backgroundColor,
				color,
				borderColor
			}
		}
	}

	const loadMembershipInputOptions = async (term: string) => {
		let options: any = []
		let response: any = await searchMembership(
			{ term: term, pdv_id: parseInt(FormData.pdv_id) },
			1,
			20
		)
		let conntractConsolidatedMainMenbership: any = null
		if (contractId && id) conntractConsolidatedMainMenbership = await loadDataEditform(parseInt(id))
		else if (contractId && !id) conntractConsolidatedMainMenbership = await loadDataEditform(parseInt(contractId))
		response?.data?.forEach((element: any) => {
			let installmentsInformation =
				' - Valor mensal: ' +
				(element?.mensal_value / 100).toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				}) +
				' - Valor anual: ' +
				(element?.anual_value / 100).toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				}) +
				' - Valor semestral: ' +
				(element?.semestral_value / 100).toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL'
				})

			if (id) {
				installmentsInformation = ''
			}

			if ((conntractConsolidatedMainMenbership && !conntractConsolidatedMainMenbership.subscriptions.find((e: any) => e.membership_tier_id == element.id)) || !conntractConsolidatedMainMenbership ||
				(FormData && FormData.status === CONTRACT_STATUS.SIGNED)
			) {
				options.push({
					label:
						'Plano ' +
						element.name +
						' ' +
						(element?.tier_type == MEMBERSHIP_TYPES.PF
							? 'PF'
							: element?.tier_type == MEMBERSHIP_TYPES.PJ
								? 'PJ'
								: element?.tier_type == MEMBERSHIP_TYPES.SVA
									? 'SVA'
									: ' ') +
						installmentsInformation,
					value: element.id,
					charge_by_holder: element.charge_by_holder
				})
			}

		})
		setMembership(options)
	}

	const debounceOptionsMembership: any = debounce(
		loadMembershipInputOptions,
		600
	)

	const loadCustomerInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = []

				let response: any = await searchCustomer({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					let cgc =
						element?.person_type == 1
							? `CPF: ${element?.cpf} - `
							: `CNPJ: ${element?.cnpj} `
					options.push({
						label: element.name + ' - ' + cgc + 'E-mail: ' + element?.email,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const loadContractInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = []

				let response: any = await searchContract(
					{ term: term, status: '3', contract_type: 'contract' },
					1,
					20
				)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label:
							'Contrato ' +
							'#' +
							element.id +
							' - de ' +
							element.business_partner.name,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsCustomer: any = debounce(loadCustomerInputOptions, 600)
	const debounceOptionsContract: any = debounce(loadContractInputOptions, 600)

	const loadAdditionalProductsInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = []

				let response: any = await searchAdditionalProducts(
					{ term: term, status: 1 },
					1,
					20
				)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label:
							element.name +
							' - ' +
							(element?.price / 100).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL'
							}),
						value: element.id,
						price: element?.price
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsAdditionalProducts: any = debounce(
		loadAdditionalProductsInputOptions,
		600
	)

	const handleMembershipChange = (e: any): void => {
		if (e?.charge_by_holder == 1) setShowChargeByHolder(true)
		else setShowChargeByHolder(false)
		setFormData({
			...FormData,
			membership_tier_id: e.target.value
		})
	}

	const handleCustomerChange = (e: any): void => {
		setFormData({
			...FormData,
			business_partner_id: e
		})
	}
	const handleContractChange = (e: any): void => {
		setFormData({
			...FormData,
			addendum_id: e
		})
	}
	const handleAdditionalProductsChange = (e: any): void => {
		let totalPrice = 0
		e?.forEach((item: any) => {
			totalPrice += item?.price
		})

		setFormData({
			...FormData,
			additional_products: e,
			total_additional_products_value: totalPrice
		})
	}

	const handleCalcContractValues = async () => {
		setShowLoadingCalculateValues(true)

		try {
			if (!FormData.recurrency || !FormData.payment_method) {
				showAlert({
					title: 'Atenção!',
					text:
						'Para calcular os valores do contrato, é necessário preencher os campos de dependente, recorrência, plano e forma de pagamento...',
					type: 'info'
				})
				setShowLoadingCalculateValues(false)
				return
			}

			let dataToCalculate: ICalculateContractValues = {
				membership_tiers_id: FormData.membership_tier_id,
				recurrency_id: FormData.recurrency,
				payment_method: FormData.payment_method,
				holder_dependents_quantity: FormData?.holder_dependents_quantity,
				additional_products_ids: FormData?.additional_products?.map(
					(item: any) => item?.value
				),
			}

			let data: IResponseCalculateContractValues = await calculateContractValues(
				dataToCalculate
			)
			let newForm = { ...FormData }
			newForm.price = data?.price / 100
			newForm.value = data?.value / 100
			newForm.discount = data?.discount / 100
			setFormData(newForm)
			setShowInputValues(true)
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				setShowLoadingCalculateValues(false)

				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Não conseguimos realizar esta ação, tente novamente mais tarde...'
			})
		}
		setShowLoadingCalculateValues(false)
	}

	const handleCalcContractValuesAddendum = async () => {
		setShowLoadingCalculateValues(true)
		try {
			if (!FormData.addendum_type) {
				showAlert({
					title: 'Atenção!',
					text:
						'Para calcular os valores do contrato aditivo, é necessário preencher o campo ação...',
					type: 'info'
				})
				setShowLoadingCalculateValues(false)
				return
			}

			let dataToCalculate: any = {
				membership_tier_id: FormData.membership_tier_id ? FormData.membership_tier_id : '',
				recurrency_id: FormData.recurrency,
				holder_dependents_quantity: FormData?.holder_dependents_quantity,
				contract_type: FormData?.contract_type,
				addendum_type: FormData?.addendum_type,
				additional_products_ids: FormData?.additional_products?.map(
					(item: any) => item?.value
				),
				id: contractId && !id ? contractId : id
			}

			let data: IResponseCalculateContractValues = await calculateContractValuesAddemdum(
				dataToCalculate
			)
			let newForm = { ...FormData }
			newForm.price = data?.price / 100
			newForm.value = data?.value / 100
			setFormData(newForm)
			setShowInputValues(true)
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				setShowLoadingCalculateValues(false)

				return
			}

			showAlert({
				type: 'warning',
				title: 'Ops...!',
				text: 'Não conseguimos realizar esta ação, tente novamente mais tarde...'
			})
		}
		setShowLoadingCalculateValues(false)
	}

	const handleTierTypes = (type: any) => {
		let string = ''
		if (type == MEMBERSHIP_TYPES.PF) {
			string = ' PF'
		} else if (type == MEMBERSHIP_TYPES.PJ) {
			string = ' PJ'
		} else if (type == MEMBERSHIP_TYPES.SVA) {
			string = ' SVA'
		}

		return string
	}

	const handleTierTypeName = (item: any) => {
		if (item?.membership_tier?.name)
			return (
				item?.membership_tier?.name +
				handleTierTypes(item?.membership_tier?.tier_type)
			)
		else return ''
	}

	const loadDataViewPageByMainContract = async () => {

		if (!contractId) return
		let contractAddendum
		let data: IDataViewContract = await loadDataEditform(parseInt(contractId)) //adicionar é o main
		if (id && contractId) {
			data = await loadDataEditform(parseInt(id)) //id é main
			contractAddendum = await loadDataEditform(parseInt(contractId)) //contractId é aditivo
		}
		let dataForm = { ...FormData }
		dataForm.recurrency = data.recurrency
		dataForm.contract_type = 'addendum'
		dataForm.business_partner_id = {
			label: data.business_partner.name,
			value: data.business_partner.id
		}
		dataForm.addendum_type = data.addendum_type
		dataForm.subscriptions = data.subscriptions
		if (contractId && !id) {
			dataForm.addendum_id = {
				label: `# ${data.id} - ${data.business_partner.name}`,
				value: data.id
			}
		}
		dataForm.pdv_id = data.pdv_id
		if (contractId && id) {
			dataForm.holder_dependents_quantity = contractAddendum?.holder_dependents_quantity
			dataForm.addendum_type = contractAddendum?.addendum_type
			dataForm.additional_products = contractAddendum?.contract_products?.map((item: any) => {
				return {
					label: item?.additional_product?.name,
					value: item?.additional_product?.id
				}
			})
			dataForm.membership_tier_id = contractAddendum?.membership_tier_id ? contractAddendum?.membership_tier_id : ''
			dataForm.addendum_id = {
				label: `# ${id} - ${data.business_partner.name}`,
				value: id
			}
			dataForm.cc_name_user = contractAddendum?.user?.name
			dataForm.status = contractAddendum.status
			dataForm.value = contractAddendum.value / 100
			dataForm.discount = contractAddendum.discount / 100
			dataForm.price = contractAddendum.price / 100
		}
		setFormData(dataForm)
		setShowInputValues(true)
	}

	const loadDataViewPage = async () => {
		try {
			if (!id) return
			setLoading(true)
			let dataForm = { ...FormData }
			let mainContractOutput: any
			let data: IDataViewContract = await loadDataEditform(parseInt(id))
			if (data.membership_tier_id === null) {
				mainContractOutput = await loadDataEditform(parseInt(data.addendum_id))
			}
			let membership_tier = data.membership_tier
				? data.membership_tier
				: mainContractOutput.membership_tier

			let payment_method = data.payment_method
			let recurrency = data.recurrency
			dataForm.membership_tier_id = membership_tier.id
			dataForm.business_partner_id = {
				label: data.business_partner.name,
				value: data.business_partner.id
			}
			dataForm.payment_method = payment_method
			dataForm.recurrency = recurrency
			dataForm.pdv_id = data.pdv_id
			dataForm.status = data.status
			dataForm.value = data.value / 100
			dataForm.discount = data.discount / 100
			dataForm.price = data.price / 100
			dataForm.holder_dependents_quantity = data.holder_dependents_quantity
			dataForm.created_at = data.created_at
			dataForm.signed_file_url = data.signed_file_url
			dataForm.original_file_url = data.original_file_url
			dataForm.holder_price = data.holder_price / 100
			dataForm.cc_name_user = data?.user?.name
			dataForm.additional_products = data?.contract_products?.map((item: any) => {
				return {
					label: item?.additional_product?.name,
					value: item?.additional_product?.id
				}
			})
			/*dataForm.addendum_id = {
				label: data.business_partner.name,
				value: data.id
			}*/
			dataForm.addendum_id = {
				label: data.business_partner.name,
				value: data.addendum_id
			}
			dataForm.addendum_type = data.addendum_type
			dataForm.contract_type = data.addendum_id === null ? 'contract' : 'addendum'
			dataForm.contract_primary_id = data.addendum_id
			dataForm.subscriptions = data.subscriptions
			setFormData(dataForm)
			setShowInputValues(true)
			setEditable(false)
		} catch (e: any) {
			console.log(e)
			if (e?.response?.data?.error_code == 'contract_not_found') {
				navigate('/errors/404')
			}
		}
		setLoading(false)
	}


	const renderStatus = (status: number | string) => {
		if (status == CONTRACT_STATUS.CREATED) {
			return (
				<SoftBadge pill bg='primary'>
					Criado
				</SoftBadge>
			)
		} else if (status == CONTRACT_STATUS.WAITING_SIGN) {
			return (
				<SoftBadge pill bg='info'>
					Aguardando Assinatura
				</SoftBadge>
			)
		} else if (status == CONTRACT_STATUS.SIGNED) {
			return (
				<SoftBadge pill bg='success'>
					Assinado
				</SoftBadge>
			)
		} else if (status == CONTRACT_STATUS.DEFAULTER) {
			return (
				<SoftBadge pill bg='warning'>
					Inadimplente
				</SoftBadge>
			)
		} else {
			return (
				<SoftBadge pill bg='danger'>
					Cancelado
				</SoftBadge>
			)
		}
	}

	const renderDate = (dateString: any) => {
		if (!dateString) return
		const date = new Date(dateString)
		const formattedDate = date.toLocaleDateString('pt-BR')
		const formattedTime = date.toLocaleTimeString('pt-BR')

		return `${formattedDate} ${formattedTime}`
	}

	const getSignedContract = async () => {
		try {
			if (!id) return
			const contractIdPage = id && contractId ? contractId : id
			setShowLoading(true)

			const result = await seeSignedContract(parseInt(contractIdPage))
			if (result) {
				window.open(result, '_blank')
			}
			setShowLoading(false)
		} catch (e: any) {
			setShowLoading(false)

			console.log(e)
			if (e?.response?.data?.error_message) {
				showAlert({
					type: 'warning',
					title: 'Ops...!',
					text: e?.response?.data?.error_message
				})
				return
			}
		}
	}

	const [membership, setMembership] = useState([])

	const searchContracts = async (page = 1, sizePerPage = 10) => {
		//limpando o state
		setListData({
			total_items: 0,
			total_pages: 0,
			current_page: 1,
			data: []
		})

		setShowLoading(true)

		try {
			const addendumFilter = {
				contract_type: 'addendum',
				contract_id: id
			}
			const addendumOutput: IResponseSearchData = await searchAddendum(
				addendumFilter,
				page,
				sizePerPage
			)
			setListData({
				total_items: addendumOutput.total_items,
				total_pages: addendumOutput.total_pages,
				current_page: addendumOutput.current_page,
				data: addendumOutput.data
			})
		} catch (e) {
			console.log(e)
		}

		setShowLoading(false)
	}

	const [listData, setListData] = useState({
		total_items: 0,
		total_pages: 0,
		current_page: 1,
		data: []
	})

	useEffect(() => {
		if (id) {
			if (!contractId) loadDataViewPage()
			else {
				handleContractLoad()
				loadDataViewPageByMainContract()
			}
		}
		else {
			if (contractId) {
				handleContractLoad()
				loadDataViewPageByMainContract()
			}
		}
		searchContracts()
		loadMembershipInputOptions('')
	}, [id, contractId])


	useEffect(() => {
		loadMembershipInputOptions('')
	}, [FormData.pdv_id])

	return (
		<Row>
			<Col lg={12}>
				<Form onSubmit={handleSubmit}>
					<Card>
						<Card.Body>
							{!id ? (
								<>
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Adicionar Contrato{contractId ? ' Aditivo' : ''}</h5>
										</Col>
										<Col sm='6' align='end'></Col>
									</Row>
									<Row className='mt-4'>
										<Col sm='6'>
											<CustomLinkLeft to='/contratos' />
										</Col>
										<Col sm='6' align='end'>
											{' '}
											<Button type='submit' variant='falcon-default'>
												<AiFillSave style={{ position: 'relative', top: '-1px' }} /> Salvar
											</Button>
										</Col>
									</Row>
								</>
							) : (
								<div>
									<Row className='d-flex justify-content-end'>
										<Col sm='6'>
											<h5>Contrato{contractId ? ' Aditivo' : ''}: #{id && !contractId ? id : contractId}</h5>
											<div>
												<h6 style={{ color: '#8d99a7' }}>
													{renderDate(FormData?.created_at)}
												</h6>
											</div>
										</Col>
										<Col sm='6' align='end'>
											<div
												style={{ display: 'flex', justifyContent: 'flex-end', gap: '4px' }}
											>
												<h5 style={{ color: '#757e89' }}>Status: </h5>{' '}
												<span style={{ position: 'relative', top: '-2px' }}>
													{renderStatus(FormData?.status)}
												</span>
											</div>
										</Col>
									</Row>
									<Row className='d-flex justify-content-end mt-3'>
										<Col sm='6' className='mt-2'>
											{id && contractId ? <CustomLinkLeft to={`/contratos/${id}`} /> : <CustomLinkLeft to={'/contratos'} />}

										</Col>
										<Col sm='6' align='end'>
											<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
												{FormData.status == CONTRACT_STATUS.CREATED ||
													FormData.status == CONTRACT_STATUS.WAITING_SIGN ? (
													<Button
														type='button'
														className='me-2'
														variant='falcon-default'
														onClick={() => {
															setControlSendModalContract({
																show: true,
																contract_id: id && contractId ? parseInt(contractId) : parseInt(id)
															})
														}}
													>
														<BsFillSendFill
															style={{ position: 'relative', top: '-1px' }}
															size={18}
														/>{' '}
														Enviar contrato
													</Button>
												) : null}{' '}
												{FormData.status == CONTRACT_STATUS.CREATED ? (
													<Button
														type='button'
														className='me-2'
														variant='falcon-default'
														onClick={() => {
															setEditable(true)
														}}
													>
														<AiFillEdit
															style={{ position: 'relative', top: '-1px' }}
															size={18}
														/>{' '}
														Editar contrato
													</Button>
												) : null}{' '}
												{FormData.status == CONTRACT_STATUS.SIGNED ? (
													<>
														<Button
															type='button'
															className={'me-2'}
															variant='falcon-default'
															onClick={() => {
																if (FormData?.contract_primary_id) {
																	window.open(
																		`/faturas?contract_id=${FormData?.contract_primary_id}`,
																		'_blank'
																	)
																} else {
																	window.open(`/faturas?contract_id=${id}`, '_blank')
																}
															}}
														>
															<FaFileInvoiceDollar
																style={{ position: 'relative', top: '-1px' }}
																size={18}
															/>{' '}
															Faturas
														</Button>
														<Button
															type='button'
															className={'me-2'}
															variant='falcon-default'
															onClick={() => {
																if (FormData?.contract_primary_id) {
																	window.open(
																		`/assinaturas?contract_id=${FormData?.contract_primary_id}`,
																		'_blank'
																	)
																} else {
																	window.open(`/assinaturas?contract_id=${id}`, '_blank')
																}
															}}
														>
															<BsFillPersonVcardFill
																style={{ position: 'relative', top: '-1px' }}
																size={18}
															/>{' '}
															Assinaturas
														</Button>
													</>
												) : null}
												<Dropdown>
													<Dropdown.Toggle variant='falcon-default' id='dropdown-basic'>
														<AiFillSetting />
													</Dropdown.Toggle>

													<Dropdown.Menu>
														{FormData.status == CONTRACT_STATUS.SIGNED ? (
															<Dropdown.Item
																onClick={getSignedContract}
																disabled={showLoading}
																target='_blank'
															>
																{' '}
																<BsDownload
																	style={{ position: 'relative', top: '-1px' }}
																/>{' '}
																Baixar contrato assinado
															</Dropdown.Item>
														) : null}

														{FormData.status != CONTRACT_STATUS.CANCELED ? (
															<Dropdown.Item
																href='#'
																onClick={() => {
																	setCancelContractModal({
																		show: true,
																		contract_id: id && contractId ? parseInt(contractId) : parseInt(id)
																	})
																}}
																disabled={showLoading}
															>
																{' '}
																<ImCancelCircle
																	style={{ position: 'relative', top: '-1px' }}
																/>{' '}
																Cancelar contrato
															</Dropdown.Item>
														) : null}

														{FormData.status == CONTRACT_STATUS.SIGNED && !(id && contractId) ? (
															<Dropdown.Item
																href={`/contratos/adicionar/${id}`}
																onClick={() => {
																	searchContracts()
																}}
															>
																{' '}
																<ImFileText
																	style={{ position: 'relative', top: '-1px' }}
																/>{' '}
																Criar Aditivo
															</Dropdown.Item>
														) : null}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</Col>
									</Row>
								</div>
							)}
						</Card.Body>
					</Card>

					<Card className='mt-3'>
						<Card.Body>
							<Row>
								<Col>
									<>
										{!contractId ? (
											<>
												<Row className='mb-2'>
													<Col lg='5'>
														<BusinessPartner
															id={id}
															styles={customStyles}
															isDisabled={contractId ? true : id && !editable ? true : false}
															loadOptions={debounceOptionsCustomer}
															value={FormData.business_partner_id}
															onChange={handleCustomerChange}
														/>
													</Col>

													<Col lg='2'>
														<DependentsQuantity
															id={id}
															onChange={handleFieldChange}
															onKeyUp={handleFieldCheck}
															isDisabled={id && !editable ? true : false}
															value={FormData.holder_dependents_quantity}
														/>
													</Col>

													<Col lg='2'>
														<Recurrency
															onChange={handleFieldChange}
															isDisabled={id && !editable ? true : false}
															value={FormData.recurrency}
														/>
													</Col>

													<Col lg='3'>
														<CalculateContractValues
															onClick={handleCalcContractValues}
															isDisabled={id && !editable ? true : false}
															showLoadingCalculateValues={showLoadingCalculateValues}
														/>
													</Col>
												</Row>

												<Row className='mb-4'>
													<Col lg={showChargeByHolder ? '4' : '6'}>
														<MembershipTier
															onChange={handleMembershipChange}
															isDisabled={id && !editable ? true : false}
															value={FormData.membership_tier_id}
															membershipList={membership}
														/>
													</Col>

													<Col lg='3'>
														<PaymentMethod
															onChange={handleFieldChange}
															isDisabled={id && !editable ? true : false}
															value={FormData.payment_method}
														/>
													</Col>

													<Col lg='3'>
														<Pdv
															onChange={handleFieldChange}
															isDisabled={id && !editable ? true : false}
															value={FormData.pdv_id}
															pdvList={dataAuth?.user?.pdvs}
														/>
													</Col>
													{showChargeByHolder ? (
														<>
															<Col lg='2'>
																<ChargeByHolder
																	onValueChange={(values: any) => handleValueFieldChange('holder_price', values)}
																	isDisabled={id && !editable ? true : false}
																	value={FormData.holder_price}
																/>
															</Col>
														</>
													) : (
														''
													)}
													{showInputValues ? (
														<>
															<Col lg='4' className='mt-2'>
																<Price
																	isDisabled={true}
																	value={FormData.price}
																/>
															</Col>

															<Col lg='4' className='mt-2'>
																<Value
																	isDisabled={true}
																	value={FormData.value}
																/>
															</Col>

															<Col lg='4' className='mt-2'>
																<Discount
																	isDisabled={true}
																	value={FormData.discount}
																/>
															</Col>
														</>
													) : null}
													<Col sm='4' className='mt-2'>
														<AdditionalProducts
															styles={customStyles}
															isDisabled={id && !editable ? true : false}
															loadOptions={debounceOptionsAdditionalProducts}
															value={FormData.additional_products}
															onChange={(e: any) => { handleAdditionalProductsChange(e); }}
														/>
													</Col>
													{id ? (
														<Col sm='4' className='mt-2'>
															<BusinessConsultant
																isDisabled={true}
																value={FormData?.cc_name_user}
															/>
														</Col>
													) : null}
												</Row>
											</>
										) : null}

										{contractId ? (
											<>
												<Row className='mb-2'>
													<Col lg='4'>
														<ContractType
															isDisabled={disabledContractType ? true : id && !editable ? true : false}
															value={FormData.contract_type}
														/>
													</Col>

													<Col lg='4'>
														<BusinessPartner
															id={id}
															styles={customStyles}
															isDisabled={disabledAddendumId ? true : id && !editable ? true : false}
															loadOptions={debounceOptionsCustomer}
															value={FormData.business_partner_id}
															onChange={handleCustomerChange}
														/>
													</Col>

													<Col lg='4'>
														<MainContractByAddendum
															styles={customStyles}
															isDisabled={disabledAddendumId ? true : id && !editable ? true : false}
															loadOptions={debounceOptionsContract}
															value={FormData.addendum_id}
															onChange={handleContractChange}
														/>
													</Col>
												</Row>

												<Row className='mb-2'>
													<Col lg='5'>
														<AddendumType
															onChange={handleFieldChange}
															isDisabled={disabledAddendumType ? true : id && !editable ? true : false}
															value={FormData.addendum_type}
														/>
													</Col>


													<Col lg='4'>
														<Pdv
															onChange={handleFieldChange}
															isDisabled={id && !editable ? true : false}
															value={FormData.pdv_id}
															pdvList={dataAuth?.user?.pdvs}
														/>
													</Col>
													<Col lg='3'>
														<CalculateContractValues
															onClick={
																handleCalcContractValuesAddendum
															}
															isDisabled={id && !editable ? true : false}
															showLoadingCalculateValues={showLoadingCalculateValues}
														/>
													</Col>
												</Row>

												<Row className='mb-2'>
													<Col lg={4}>
														<MembershipTier
															onChange={handleMembershipChange}
															isDisabled={id && !editable ? true : false}
															value={FormData.membership_tier_id}
															membershipList={membership}
														/>
													</Col>


													<Col lg='2'>
														<DependentsQuantity
															id={id}
															onChange={handleFieldChange}
															onKeyUp={handleFieldCheck}
															isDisabled={id && !editable ? true : false}
															value={FormData.holder_dependents_quantity}
														/>
													</Col>

													<Col lg='6'>
														<AdditionalProducts
															styles={customStyles}
															isDisabled={id && !editable ? true : false}
															loadOptions={debounceOptionsAdditionalProducts}
															value={FormData.additional_products}
															onChange={(e: any) => { handleAdditionalProductsChange(e); }}
														/>
													</Col>
													{showInputValues ? (
														<>
															<Col lg='4' className='mt-2'>
																<Price
																	isDisabled={true}
																	value={FormData.price}
																	isAddendum={true}
																/>
															</Col>

															{/* <Col lg='4' className='mt-2'>
																<Value 
																	isDisabled={true}
																	value={FormData.value}
																/>
															</Col> */}
															{/* 
															<Col lg='4' className='mt-2'>
																<Discount 
																	isDisabled={true}
																	value={FormData.discount}
																/>
															</Col> */}
														</>
													) : null}



													{id ? (
														<Col sm='4' className='mt-2'>
															<BusinessConsultant
																isDisabled={true}
																value={FormData?.cc_name_user}
															/>
														</Col>
													) : null}
												</Row>
											</>
										) : null}
									</>
								</Col>
							</Row>
						</Card.Body>
					</Card>

					{editable && (
						<Card className='mt-3'>
							<Card.Body>
								<Row className='d-flex justify-content-end'>
									<Col align='end'>
										<Button
											type='button'
											className='me-2'
											variant='falcon-default'
											onClick={handleEditSubmit}
										>
											<GiConfirmed
												style={{ position: 'relative', top: '-1px' }}
												size={18}
											/>{' '}
											Salvar
										</Button>
										<Button
											type='button'
											variant='falcon-default'
											onClick={() => {
												setEditable(false)
											}}
										>
											<GiCancel style={{ position: 'relative', top: '-1px' }} size={18} />{' '}
											Fechar
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					)}

					{id && !contractId && listData?.data?.length > 0 ? (
						<Card className='mt-3'>
							<Card.Body>
								{showLoading ? (
									<CustomLoading />
								) : (
									<div>
										<Table responsive striped>
											<thead>
												<tr>
													<th scope='col'>ID</th>
													<th scope='col'>Cliente</th>
													<th scope='col'>Plano</th>
													<th scope='col'>Tipo</th>
													<th scope='col'>Criado em</th>
													<th scope='col'>Status</th>
													<th className='text-end' scope='col'>
														Ações
													</th>
												</tr>
											</thead>

											<tbody>
												{listData?.data?.length > 0
													? listData?.data?.map((item: IDataContract, index: number) => (
														<tr key={item.id}>
															<td>{item?.id}</td>
															<td>{item?.business_partner?.name}</td>
															<td>{handleTierTypeName(item)}</td>
															<td>
																<b>Aditivo - {item?.addendum_type}</b>
															</td>
															<td>
																{item?.created_at
																	? new Date(item?.created_at).toLocaleDateString('pt-BR')
																	: null}
															</td>
															<td>{renderStatus(item?.status)}</td>
															<td className='text-end'>
																<FontAwesomeIcon
																	icon={'eye'}
																	style={{
																		color: '#2c7be5',
																		fontSize: '20px',
																		cursor: 'pointer',
																		position: 'relative',
																		left: '-10px'
																	}}
																	onClick={() => {
																		navigate(`/contratos/${id}/aditivo/${item?.id}`)
																	}}
																/>
															</td>
														</tr>
													))
													: null}
											</tbody>
										</Table>
										<CustomPagination
											data={listData}
											handlePageChange={e => {
												searchContracts(e)
											}}
										/>
									</div>
								)}
							</Card.Body>
						</Card>
					) : (
						''
					)}

					<CancelContractModal
						FormData={FormData}
						setFormData={setFormData}
						handleFieldChange={handleFieldChange}
						show={controlCancelContractModal.show}
						contract_id={controlCancelContractModal.contract_id}
						onClose={() => {
							setCancelContractModal({ show: false, contract_id: null })
						}}
					/>
					<SendContractModal
						FormData={FormData}
						setFormData={setFormData}
						show={controlSendModalContract.show}
						contract_id={controlSendModalContract.contract_id}
						onClose={response => {
							setControlSendModalContract({ show: false, contract_id: null })
							if (response?.action == 'success') {
								loadDataViewPage()
								if (contractId && id) {
									loadDataViewPageByMainContract()
								}
							}
						}}
					/>
				</Form>
			</Col>
		</Row>
	)
}

export default FormContracts


