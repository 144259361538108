import { Form } from "react-bootstrap";
import PAYMENT_METHOD from 'helpers/paymentMethodTypes'

interface PaymentMethodProps {
  onChange: any
  isDisabled: boolean
  value: any
}

export function PaymentMethod({ onChange, isDisabled, value }: PaymentMethodProps) {
  return (
    <Form.Group>
      <Form.Label>Método de pagamento</Form.Label>
      <Form.Select
        name='payment_method'
        onChange={onChange}
        disabled={isDisabled}
        value={value}
      >
        <option value=''>Selecionar</option>
        <option value={PAYMENT_METHOD.TICKET}>Boleto</option>
        <option value={PAYMENT_METHOD.CREDIT_CARD}>Cartão de Crédito</option>
        <option value={PAYMENT_METHOD.PIX}>PIX</option>
        <option value={PAYMENT_METHOD.CCP}>Cartão de Crédito Parcelado</option>
      </Form.Select>
    </Form.Group>
  )
}