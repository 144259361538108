import React, { useState, useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import { FiFilter } from 'react-icons/fi'
import { search as searchMembership } from 'services/vittamais-core/membership-tier/membership'
import { search as searchBusinessPartners } from 'services/vittamais-core/customer/customer'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import AppContext from 'context/Context'
import CONTRACT_STATUS from 'helpers/contractStatus'
import useAuth from 'hooks/useAuth'
import MEMBERSHIP_TYPES from 'helpers/membershipTypes'
interface FilterDropdownProps {
	filtersData: {
		bp_id: string
		status: string
		membership_tier_id: string
		find_text: string
		pdv_id: string
		contract_type: string
	}
	setFiltersData: any
	searchContracts: () => void
}

const FilterDropdown: React.FC<FilterDropdownProps> = ({
	filtersData,
	setFiltersData,
	searchContracts
}) => {
	const { dataAuth } = useAuth()
	const {
		config: { isDark }
	} = useContext(AppContext)
	const [isOpen, setIsOpen] = useState(false)
	const handleToggle = () => {
		setIsOpen(!isOpen)
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			backgroundColor: isDark ? '#0b1727' : '',
			color: isDark ? 'white' : '',
			borderColor: isDark ? '#283444' : '#dae3f0',
			minHeight: '30px',
			height: '30px'
		})
	}

	const loadBpInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = [
					{
						label: 'Todos',
						value: ''
					}
				]

				let response: any = await searchBusinessPartners({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					let cgc =
						element?.person_type == 1
							? `CPF: ${element?.cpf} - `
							: `CNPJ: ${element?.cnpj} `
					options.push({
						label: element.name + ' - ' + cgc + 'E-mail: ' + element?.email,
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsBp: any = debounce(loadBpInputOptions, 600)
	const loadMembershipInputOptions = async (term: string) => {
		return new Promise(async (resolve, reject) => {
			try {
				let options: any = []

				let response: any = await searchMembership({ term: term }, 1, 20)

				if (!response || response?.data?.length <= 0) return resolve(options)

				response?.data?.forEach((element: any) => {
					options.push({
						label:
							'Plano ' +
							element.name +
							' ' +
							(element?.tier_type == MEMBERSHIP_TYPES.PF
								? 'PF'
								: element?.tier_type == MEMBERSHIP_TYPES.PJ
								? 'PJ'
								: element?.tier_type == MEMBERSHIP_TYPES.SVA
								? 'SVA'
								: ' ') +
							' - Valor mensal: ' +
							(element?.mensal_value / 100).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL'
							}) +
							' - Valor anual: ' +
							(element?.anual_value / 100).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL'
							}) +
							' - Valor semestral: ' +
							(element?.semestral_value / 100).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL'
							}),
						value: element.id
					})
				})

				return resolve(options)
			} catch (e) {
				console.error(e)
				return reject([])
			}
		})
	}

	const debounceOptionsMembership: any = debounce(
		loadMembershipInputOptions,
		600
	)
	const clearInputs = () => {
		let newData = { ...filtersData }
		newData.bp_id = ''
		newData.membership_tier_id = ''
		newData.pdv_id = ''
		newData.status = ''
		newData.find_text = ''
		newData.contract_type = ''
		setFiltersData(newData)
	}
	const applyFilters = () => {
		setIsOpen(false)
		searchContracts()
	}
	const changeFiltersData = (name: string, e: any) => {
		if (name == 'status') {
			setFiltersData({ ...filtersData, status: e?.target?.value })
		} else if (name == 'pdv_id') {
			setFiltersData({ ...filtersData, pdv_id: e?.target?.value })
		} else if (name == 'contract_type') {
			setFiltersData({ ...filtersData, contract_type: e?.target?.value })
		} else {
			setFiltersData({ ...filtersData, [name]: e })
		}
		// searchSubscriptions()
	}
	return (
		<Dropdown show={isOpen} onToggle={handleToggle}>
			<Dropdown.Toggle variant='falcon-default'>
				<FiFilter
					size={20}
					style={{ position: 'relative', left: '-4px', top: '-2px' }}
				/>{' '}
				Filtros
			</Dropdown.Toggle>
			<Dropdown.Menu
				style={{
					position: 'absolute',
					left: 0,
					right: 0,
					minWidth: 'calc(100vw - 300px)',
					maxWidth: '100%',
					width: 'auto'
				}}
				className='text-dark mt-1'
			>
				<Card.Body style={{ paddingTop: '10px' }}>
					<Row>
						<Col lg='3'>
							{' '}
							<Form.Group>
								<Form.Label>Cliente</Form.Label>
								<AsyncSelect
									styles={customStyles}
									name='business_partner_id'
									placeholder='Selecionar cliente'
									isClearable
									defaultOptions
									loadOptions={debounceOptionsBp}
									onChange={e => {
										changeFiltersData('bp_id', e)
									}}
									value={filtersData.bp_id}
									noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
								/>
							</Form.Group>
						</Col>
						<Col lg='3'>
							<Form.Group>
								<Form.Label>Plano</Form.Label>
								<AsyncSelect
									styles={customStyles}
									name='membership_tier_id'
									placeholder='Selecionar plano'
									isClearable
									defaultOptions
									loadOptions={debounceOptionsMembership}
									value={filtersData.membership_tier_id}
									onChange={e => {
										changeFiltersData('membership_tier_id', e)
									}}
									noOptionsMessage={() => 'Sem Resultados. Digite para Buscar'}
								/>
							</Form.Group>
						</Col>
						<Col lg='2'>
							<Form.Group>
								<Form.Label>PDV</Form.Label>
								<Form.Select
									size='sm'
									name='pdv_id'
									onChange={e => {
										changeFiltersData('pdv_id', e)
									}}
									value={filtersData.pdv_id}
								>
									<option value=''>Selecionar</option>
									{dataAuth?.user?.pdvs?.map((item: any, index: number) => (
										<option key={item.id} value={item.id}>
											{item?.name}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg='2'>
							{' '}
							<Form.Group>
								<Form.Label>Status</Form.Label>
								<Form.Select
									size='sm'
									name='status'
									value={filtersData.status}
									onChange={e => {
										changeFiltersData('status', e)
									}}
								>
									<option value=''>Todos</option>
									<option value={CONTRACT_STATUS.CANCELED}>Cancelado</option>
									<option value={CONTRACT_STATUS.CREATED}>Criado</option>
									<option value={CONTRACT_STATUS.SIGNED}>Assinado</option>
									<option value={CONTRACT_STATUS.WAITING_SIGN}>
										Aguardando Assinatura
									</option>
									<option value={CONTRACT_STATUS.DEFAULTER}>
										Inadimplente
									</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg='2'>
							{' '}
							<Form.Group>
								<Form.Label>Tipo</Form.Label>
								<Form.Select
									size='sm'
									name='contract_type'
									value={filtersData.contract_type}
									onChange={e => {
										changeFiltersData('contract_type', e)
									}}
								>
									<option value='contract'>Contrato</option>
									<option value='addendum'>Aditivo</option>
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
				</Card.Body>
				<hr />
				<Row>
					<Col align='end'>
						<Button
							size='sm'
							variant='falcon-default'
							type='button'
							className='me-2'
							style={{ position: 'relative', left: '-16px' }}
							onClick={() => {
								clearInputs()
							}}
						>
							Limpar Filtros
						</Button>{' '}
						<Button
							size='sm'
							type='button'
							style={{ position: 'relative', left: '-16px' }}
							onClick={() => {
								applyFilters()
							}}
						>
							Aplicar Filtros
						</Button>
					</Col>
				</Row>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default FilterDropdown
